import { useTheme } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

export function ItemCrowdfundingProgress({ value }: { value: number }) {
  const theme = useTheme();

  return (
    <LinearProgress
      variant="determinate"
      value={Math.min(value, 100)}
      sx={{
        height: 10,
        borderRadius: 5,
        "&.MuiLinearProgress-colorPrimary ": {
          background: theme.palette.grey[200],
        },
        "& .MuiLinearProgress-bar": {
          borderRadius: 5,
          // @ts-expect-error - TODO: fix mainGradient not in types
          background: theme.palette.primary.mainGradient,
        },
      }}
    />
  );
}
